import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['activeButton', 'inactiveButton', 'liveForm'];

  private activeButtonTarget!: HTMLButtonElement;
  private inactiveButtonTarget!: HTMLButtonElement;
  private liveFormTarget!: HTMLFormElement;

  connect() {
    this.liveFormTarget.addEventListener('unblock', () => this.unblock());
  }

  disconnect() {
    this.liveFormTarget.removeEventListener('unblock', () => this.unblock());
  }

  block(_event: Event) {
    this.inactiveButtonTarget.classList.remove('hiddenDiv');
    this.activeButtonTarget.classList.add('hiddenDiv');
  }

  unblock() {
    this.inactiveButtonTarget.classList.add('hiddenDiv');
    this.activeButtonTarget.classList.remove('hiddenDiv');
  }
}
